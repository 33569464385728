import { Injectable } from "@angular/core";
import { AppCommonService } from "@services/app-common.service";
import { AuthService } from "@services/auth.service";
import { HttpService } from "@services/http.service";
import { TokenService } from "@services/token.service";
import { Subject } from "rxjs";
import { commonApiUrls } from "src/app/constants/api/api-constants";
import { HeaderConfig } from "src/app/constants/application/application-constants";
import { ToastMessages } from "src/app/constants/toast/toast-message-constants";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: "root" })
export class HeaderService {
    public showDesktopTopBanner: boolean = true;
    constructor(
        private authService: AuthService, 
        private commonService: AppCommonService,
        private tokenService: TokenService,
        private httpService: HttpService
    ) { }
    
    //#region Input Observables
    private headerConfigSub = new Subject<any>();
    private searchBrandTermSub = new Subject<any>();
    private searchTermSub = new Subject<any>();
    private searchResultsSub = new Subject<any>();
    private logoutSubscription = new Subject<any>();
    private searchLoaderSubscription = new Subject<any>();
    public handleCustomBack = new Subject<any>();
    
    setHeaderConfig(conf: string, val: any){
        this.headerConfigSub.next({'config' : conf, 'value': val});
    }
    
    getHeaderConfigSub(){
        return this.headerConfigSub.asObservable();
    }

    setSearchBrandTerm(val: any){
        this.searchBrandTermSub.next(val);
    }
    
    getSearchBrandTermSub(){
        return this.searchBrandTermSub.asObservable();
    }

    setSearchTerm(val: any){
        this.searchTermSub.next(val);
    }
    
    getSearchTermSub(){
        return this.searchTermSub.asObservable();
    }

    setSearchResults(val: any){
        this.searchResultsSub.next(val);
    }
    
    getSearchResultsSub(){
        return this.searchResultsSub.asObservable();
    }
    //#endregion

    //#region Output Observables

    private onBrandSearchSub = new Subject<any>();
    private onSearchSub = new Subject<any>();
    private onSearchEnterSub = new Subject<any>();
    private onSocialShareSub = new Subject<any>();
    private onSendToShopSub = new Subject<any>();
    private onSelectedMenuSub = new Subject<any>();

    emitOnBrandSearch(val: any){
        this.onBrandSearchSub.next(val);
    }
    
    getOnBrandSearchSub(){
        return this.onBrandSearchSub.asObservable();
    }

    emitOnSearch(val: any){
        this.onSearchSub.next(val);
    }
    
    getOnSearchSub(){
        return this.onSearchSub.asObservable();
    }

    emitOnSearchEnter(val: any){
        this.onSearchEnterSub.next(val);
    }
    
    getOnSearchEnterSub(){
        return this.onSearchEnterSub.asObservable();
    }

    emitOnSocialShare(val: any){
        this.onSocialShareSub.next(val);
    }
    
    getOnSocialShareSub(){
        return this.onSocialShareSub.asObservable();
    }

    emitOnSendToShop(val: any){
        this.onSendToShopSub.next(val);
    }
    
    getOnSendToShopSub(){
        return this.onSendToShopSub.asObservable();
    }

    //#endregion

    public setLogout(){
        this.logoutSubscription.next(true);
    }
    public getLogout(){
        return this.logoutSubscription.asObservable();
    }

    public setSearchLoaderVisibility(val) {
        this.searchLoaderSubscription.next(val);
    }
    public getSearchLoaderVisibility() {
        return this.searchLoaderSubscription.asObservable();
    }

    deleteToken() {
        this.tokenService.deleteToken();
    }
    updateTokenAfterLogout(visitorppl) {
        this.tokenService.generateToken(visitorppl).
        then((token) => {
            this.authService.getInitState(false, true);
            this.commonService.showToastMsg(ToastMessages.loggedOutSuccessfully);
        });
    }
    getHttpError() {
        return this.httpService.getHttpError();
    }
    setHttpError() {
        this.httpService.setHttpError("0");
    }
    getHeaderMenus() {
        return this.httpService.getFromApi(environment.apiBaseUrl + commonApiUrls.headerMenu, { isGenericCall: true });
    }
    getSuperMenus() {
        return this.httpService.getFromApi(environment.apiBaseUrl + commonApiUrls.superMenu, { isGenericCall: true });
    }

    setAllHeaderConfig(mapping) {
        Object.entries(mapping).forEach(([config, value]) => {
            if (config !== 'url' && config !== 'pageName') {
                this.setHeaderConfig(HeaderConfig[config], value);
            }
        });
    }
}
